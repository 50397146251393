import * as api from './apiUtils';
import { getCookie } from './dataApi';

function getConfig(profileKey) {
  return { headers: { property: profileKey, user: getCookie('mi-user') } };
}

export function getPropertyProfile(profileKey, forEditing) {
  return api
    .get(
      'API/PropertyProfile/GetPropertyProfile?forEditing=' +
        (forEditing ? 'true' : 'false'),
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function savePropertyProfile(profileKey, serviceProfile, isCompleted) {
  console.log('serviceProfile', serviceProfile);
  return api
    .post(
      'API/PropertyProfile/SavePropertyProfile?isCompleted=' +
        (isCompleted ? 'true' : 'false'),
      serviceProfile,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function listResidentOnboarding(profileKey, fullHistory) {
  return api
    .get(
      'API/PropertyProfile/ListResidentOnboarding?fullHistory=' +
        (fullHistory ? 'true' : 'false'),
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function restoreDefaults(profileKey) {
  return api
    .post('API/PropertyProfile/RestoreDefaults', null, getConfig(profileKey))
    .then((response) => {
      return response.data;
    });
}

export function listResidentPet(profileKey) {
  return api
    .get('API/PropertyProfile/ListResidentPet', getConfig(profileKey))
    .then((response) => {
      return response.data;
    });
}

export function listResidentVehicle(profileKey) {
  return api
    .get('API/PropertyProfile/ListResidentVehicle', getConfig(profileKey))
    .then((response) => {
      return response.data;
    });
}

export function saveResidentPetNotes(
  profileKey,
  residentID,
  residentPetID,
  notes
) {
  return api
    .post(
      'API/PropertyProfile/SaveResidentPetNotes?residentID=' +
        residentID +
        '&residentPetID=' +
        residentPetID,
      { Value: notes },
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveResidentVehicleNotes(
  profileKey,
  residentID,
  residentVehicleID,
  notes
) {
  return api
    .post(
      'API/PropertyProfile/SaveResidentVehicleNotes?residentID=' +
        residentID +
        '&residentVehicleID=' +
        residentVehicleID,
      { Value: notes },
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function listUnit(profileKey) {
  return api
    .get('API/PropertyProfile/ListUnit', getConfig(profileKey))
    .then((response) => {
      return response.data;
    });
}

export function listOccupant(profileKey, residentID) {
  return api
    .get(
      'API/PropertyProfile/ListOccupant?residentID=' + residentID,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function listResidentMessage(profileKey, residentID) {
  return api
    .get(
      'API/PropertyProfile/ListResidentMessage?residentID=' + residentID,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function sendResidentMessage(profileKey, request) {
  return api
    .post(
      'API/PropertyProfile/SendResidentMessage',
      request,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function listPropertyCalendar(profileKey) {
  return api
    .get('API/PropertyProfile/ListCalendar', getConfig(profileKey))
    .then((response) => {
      return response.data;
    });
}

export function listAllCalendarEvent(profileKey, startDate, endDate) {
  return api
    .get(
      'API/PropertyProfile/ListAllCalendarEvent?startDate=' +
        encodeURIComponent(startDate) +
        '&endDate=' +
        encodeURIComponent(endDate),
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function setResidentOnNotice(profileKey, residentID, dateMoveOut) {
  return api
    .post(
      'API/PropertyProfile/SetResidentOnNotice?residentID=' +
        residentID +
        '&dateMoveOut=' +
        encodeURIComponent(dateMoveOut),
      null,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function listPropertyUser(profileKey) {
  return api
    .get('API/PropertyProfile/ListPropertyUser', getConfig(profileKey))
    .then((response) => {
      return response.data;
    });
}

export function savePropertyUser(
  profileKey,
  email,
  firstName,
  lastName,
  colorCode,
  agentID,
  isAccountOwner
) {
  return api
    .post(
      'API/PropertyProfile/SavePropertyUser',
      {
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        ColorCode: colorCode,
        AgentID: agentID ? agentID : 0,
        IsAccountOwner: isAccountOwner
      },
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function deletePropertyUser(profileKey, userID, agentID) {
  return api
    .post(
      'API/PropertyProfile/DeletePropertyUser?userID=' +
        (userID ? userID : '0') +
        '&agentID=' +
        (agentID ? agentID : '0'),
      null,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}

export function saveSmarterChoiceSettings(profileKey, smarterChoiceSettings) {
  return api
    .post(
      'API/PropertyProfile/SaveSmarterChoiceSettings',
      smarterChoiceSettings,
      getConfig(profileKey)
    )
    .then((response) => {
      return response.data;
    });
}
